import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchGamesList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/game', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchGame(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/game/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addGame(ctx, {game}) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/game/create', game)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        saveGame(ctx, {id, game}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/game/${id}`, game)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteGame(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/game/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
