<template>
  <b-card
      v-if="gameData"
      class="game-edit-wrapper"
  >

    <!-- form -->
    <b-form class="mt-2" @submit.prevent="onSubmit">
      <b-overlay
          :show="isLoading"
          rounded="sm"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
                label="Title"
                label-for="game-edit-title"
                class="mb-2"
            >
              <b-form-input
                  id="game-edit-title"
                  v-model="gameData.name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                label="Type"
                label-for="game-edit-type"
                class="mb-2"
            >
              <v-select
                  id="game-edit-type"
                  v-model="gameData.type"
                  :options="typeOptions"
                  :reduce="val => val.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-tabs
                v-if="gameData.rounds"
                pills
            >
              <!-- Tab: Раунд Х -->
              <b-tab :active="roundIndex === 0" v-for="(round, roundIndex)  in gameData.rounds"
              :key="round.id"
              >
                <template #title>
                  <feather-icon
                      icon="FolderIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{ round.name }}</span>
                </template>
                <ul>
                  <b-list-group-item
                      v-for="(topic, topicIndex)  in round.topics"
                      :key="topic.id"
                      tag="li"
                  >
                    <div class="d-flex">
                      <div class="ml-25">
                        <b-card-text class="font-weight-bold mb-0">
                          {{ topic.id }}: {{ topic.name }}


                            <draggable
                                :list="topic.questions"
                                :group="{name:'questions'+topicIndex}"
                                class="row mb-1 cursor-move"
                                tag="div"
                                @change="log(roundIndex, topicIndex)"
                            >
                              <b-col
                                  lg="2"
                                  sm="4"
                                  v-for="question in topic.questions"
                                  :key="question.id"

                              >
                                  <statistic-card-horizontal
                                      icon="CpuIcon"
                                      :statistic=question.price
                                      :statistic-title=question.answer

                                  />
                              </b-col>
<!--                              <b-col
                                  lg="2"
                                  sm="4"
                                  v-for="newquestion in [1,2,3]"
                              >
                                {{ newquestion }}
                              </b-col>-->
                            </draggable>


                        </b-card-text>
                      </div>
                    </div>
                  </b-list-group-item>
                </ul>
              </b-tab>

              <!-- New Tab Button (Using tabs-end slot) -->
              <template #tabs-end>
                <b-nav-item
                    role="presentation"
                    @click.prevent="newTab()"
                >
                  <b>+</b>
                </b-nav-item>
              </template>
            </b-tabs>
          </b-col>
          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                class="mr-1"
                :disabled="isLoading"
            >
              <span v-if="isLoading">
                <b-spinner small />
                Сохраняем...
              </span>
              <span v-else>
                Сохранить
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTabs, BTab,
  BNavItem,
  BOverlay,
  BListGroupItem,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import gameStoreModule from "@/views/game/gameStoreModule";
import draggable from 'vuedraggable';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    BTabs,
    BTab,
    BNavItem,
    BOverlay,
    BListGroupItem,
    draggable,
    StatisticCardHorizontal,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      typeOptions: [
        {
          value: 'rock',
          label: 'Рок',
        },
        {
          value: 'rusrock',
          label: 'Русский Рок',
        },
        {
          value: 'foreignrock',
          label: 'Зарубежный Рок',
        },
        {
          value: 'ugadai-rock',
          label: 'Угадай мелодию - Рок',
        },
        {
          value: 'ugadai-pop',
          label: 'Угадай мелодию - Поп',
        },
        {
          value: 'films',
          label: 'Фильмы',
        },
        {
          value: 'serials',
          label: 'Сериалы',
        },
        {
          value: 'football',
          label: 'Футбол',
        },
      ],
    }
  },
  setup() {
    const gameData = ref(null)
    const emptyGame = ref({
      "name": 'Empty game',
      "rounds": [
        {
          "name": "Раунд 1",
          "number": 1,
          "topics": [
            {
              "order": 1,
              "name": "Тема 1",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 2,
              "name": "Тема 2",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 3,
              "name": "Тема 3",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 4,
              "name": "Тема 4",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 5,
              "name": "Тема 5",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 6,
              "name": "Тема 6",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
          ]
        },
      ]
    })
    const isLoading = ref(true)
    const GAMES_STORE_MODULE_NAME = 'games'
    if (!store.hasModule(GAMES_STORE_MODULE_NAME)) store.registerModule(GAMES_STORE_MODULE_NAME, gameStoreModule)
    onUnmounted(() => {
      if (store.hasModule(GAMES_STORE_MODULE_NAME)) store.unregisterModule(GAMES_STORE_MODULE_NAME)
    })

    const newTab = function () {
      let number = gameData.value.rounds.length + 1;
      let item = {
        name: `Раунд ${number}`,
      }
      gameData.value.rounds.push(item)
    }

    const newEmptyGame = function () {
      let rounds = 3
      let topics = 6
      let questions = 5
      let emptyGame = {}
      let round = {}
      let topic = {}
      let question = {}
      let currentRound = 0
      let currentTopic = 0
      let currentQuestion = 0
      let roundNumber = 1
      let topicNumber = 1
      let questionNumber = 1
      emptyGame.name = 'Empty game'
      emptyGame.rounds = []
      while (currentRound < rounds) {
        round = {}
        roundNumber = currentRound + 1
        round.name = `Раунд ${roundNumber}`
        round.number = roundNumber
        round.topics = []
        while (currentTopic < topics) {
          topic = {}
          topicNumber = currentTopic + 1
          topic.name = `Тема ${topicNumber}`
          topic.order = topicNumber
          topic.questions = []
          while (currentQuestion < questions) {
            question = {}
            questionNumber = currentQuestion + 1
            question.id = null
            question.order = questionNumber
            question.price = roundNumber * questionNumber * 100

            topic.questions.push(question)
            currentQuestion++
          }
          currentQuestion = 0
          round.topics.push(topic)
          currentTopic++
        }
        currentTopic = 0
        emptyGame.rounds.push(round)
        currentRound++
      }

      return emptyGame
    }

    const recalculateTopic = function (round, topic) {
      console.log('RECALC: ', round, topic)
      console.log(gameData.value.rounds)
      console.log(gameData.value.rounds[round])
      console.log(gameData.value.rounds[round].topics)
      console.log(gameData.value.rounds[round].topics[topic])
    }

    const log = function (round, topic) {
      recalculateTopic(round, topic)
    }

    const fillEmptySlots = function (gameData) {
      gameData.rounds.map(function(round, key) {
        //console.log(key, round)
      })
      return gameData
    }

    store.dispatch('games/fetchGame', {id: router.currentRoute.params.id})
        .then(response => {
          gameData.value = fillEmptySlots(response.data.data)
          console.log('newEmptyGam')
          let t = newEmptyGame()
          console.log(t)
          console.log('t')
          isLoading.value = false
        })
        .catch(error => {
          /*if (error.response.status === 404) {
            gameData.value = undefined
          }*/
          isLoading.value = false
        })

    const onSubmit = () => {
      isLoading.value = true
      store.dispatch('games/saveGame', { id: router.currentRoute.params.id, game: gameData.value })
          .then(response => {
            gameData.value = response.data.data
            isLoading.value = false
          }).catch(error => {
            isLoading.value = false
          })
    }

    return {
      gameData,
      newTab,
      onSubmit,
      isLoading,
      log,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
